<template>
  <div class="page">
    <div class="tabView">
      <div class="tabViewT">
        <div class="tabItem" :class="tab == 0 ? 'active' : ''" @click="handelTabClick(0)">短信设置</div>
        <div class="tabItem" v-if="zthySignStatus && zthySignStatus == 2" :class="tab == 1 ? 'active' : ''" @click="handelTabClick(1)">短信模版</div>
        <div class="tabItem" v-if="zthySignStatus && zthySignStatus == 2" :class="tab == 2 ? 'active' : ''" @click="handelTabClick(2)">短信记录</div>
        <div class="tabItem" v-if="zthySignStatus && zthySignStatus == 2" :class="tab == 3 ? 'active' : ''" @click="handelTabClick(3)">充值记录</div>
      </div>
      <div class="tableViewB" v-if="tab == 0"
        style="width: 100%;padding: 20px 30px;box-sizing: border-box;height:700px;overflow: auto;display: flex;">
        <div style="width: 50%;">
          <div class="viewTitle">短信设置 <span v-if="zthySMSCount">剩余{{ zthySMSCount }}条</span></div>
          <el-button @click="handleApplyClick" v-if="show == false">申请开通</el-button>
          <div class="comInput" v-if="show === true">
            <el-input v-model="Signature" style="width:200px;" placeholder="请输入签名"></el-input>
            <el-button type="primary" v-if="zthySignStatus == 0 || zthySignStatus == 3"
              @click="changeSignature">申请</el-button>
            <span v-if="zthySignStatus !== 0 && zthySignStatus !== 2">{{ zthySignStatus == 1 ? '审核中' : '审核失败' }}</span>
          </div>
          <div v-if="zthySignStatus && zthySignStatus == 2">
            <div class="comView">
              <div class="comName">收银默认发送短信:	<el-tooltip class="item" effect="dark"
							content="例:尊敬的X先生，您在2024年8月30日 在XX宠物店消费了320元，感谢您的回顾，期待您的再次光临" placement="right-start">
							<i class="el-icon-question" style="color: #F77E04;margin-left: 10px;font-size: 20px;"></i>
						</el-tooltip></div>
              <el-radio-group v-model="smsSendConfig.sendCustom" @change="handleUpdateChange(0)">
                <el-radio :label="0">不发送</el-radio>
                <el-radio :label="1">发送全部</el-radio>
                <el-radio :label="2">仅发送余额</el-radio>
              </el-radio-group>
            </div>
            <div class="comView">
              <div class="comName">充值默认发送短信:<el-tooltip class="item" effect="dark"
							content="例:尊敬的X先生，您的账户在2024年8月20日在XX宠物店充值了3000元，当前可用余额6999.99元" placement="right-start">
							<i class="el-icon-question" style="color: #F77E04;margin-left: 10px;font-size: 20px;"></i>
						</el-tooltip></div>
              <el-radio-group v-model="smsSendConfig.sendRecharge" @change="handleUpdateChange(1)">
                <el-radio :label="0">不发送</el-radio>
                <el-radio :label="1">发送</el-radio>
              </el-radio-group>
            </div>
            <div class="comView">
              <div class="comName">开户发送短信:<el-tooltip class="item" effect="dark"
							content="例:尊敬的X先生，感谢您选择XX宠物店注册成为会员，我们将竭诚为您服务，祝您生活愉快。" placement="right-start">
							<i class="el-icon-question" style="color: #F77E04;margin-left: 10px;font-size: 20px;"></i>
						</el-tooltip></div>
              <el-radio-group v-model="smsSendConfig.sendCreateMember" @change="handleUpdateChange(2)">
                <el-radio :label="0">不发送</el-radio>
                <el-radio :label="1">发送</el-radio>
              </el-radio-group>
            </div>

            <div class="comView">
              <div class="comName">宠物生日发送短信:<el-tooltip class="item" effect="dark"
							content="例:今天是您的爱宠生日，XX宠物店祝您的爱宠生日快乐！希望TA在未来的每一天里，健康开心。[XX宠物]" placement="right-start">
							<i class="el-icon-question" style="color: #F77E04;margin-left: 10px;font-size: 20px;"></i>
						</el-tooltip></div>
              <el-radio-group v-model="smsSendConfig.sendPetBirday" @change="handleUpdateChange(3)">
                <el-radio :label="0">不发送</el-radio>
                <el-radio :label="1">发送</el-radio>
              </el-radio-group>
            </div>
            <div class="comView">
              <div class="comName">会员生日发送短信:<el-tooltip class="item" effect="dark"
							content="例:XX宠物店祝您生日快乐！！！祝您在未来的每一天里，天天开心。[XX宠物]" placement="right-start">
							<i class="el-icon-question" style="color: #F77E04;margin-left: 10px;font-size: 20px;"></i>
						</el-tooltip></div>
              <el-radio-group v-model="smsSendConfig.sendMemberBirday" @change="handleUpdateChange(4)">
                <el-radio :label="0">不发送</el-radio>
                <el-radio :label="1">发送</el-radio>
              </el-radio-group>
            </div>
            <div class="comView">
              <div class="comName">次卡提醒发送短信:<el-tooltip class="item" effect="dark"
							content="例:尊敬的用户：您的买五送二洗澡卡于2024年9月10日在XX宠物店消费一次，当前剩余3次。[XX宠物]" placement="right-start">
							<i class="el-icon-question" style="color: #F77E04;margin-left: 10px;font-size: 20px;"></i>
						</el-tooltip></div>
              <el-radio-group v-model="smsSendConfig.sendCardCustem" @change="handleUpdateChange(5)">
                <el-radio :label="0">不发送</el-radio>
                <el-radio :label="1">发送</el-radio>
              </el-radio-group>
            </div>
            <div class="comView">
              <div class="comName">预约提醒发送短信:<el-tooltip class="item" effect="dark"
							content="例:尊敬的X先生，您预约的洗美服务将在2024年9月10日09:00进行，请您携带爱宠提前到店。如有变动，请尽快联系我们调整。期待与您相见！" placement="right-start">
							<i class="el-icon-question" style="color: #F77E04;margin-left: 10px;font-size: 20px;"></i>
						</el-tooltip></div>
              <el-radio-group v-model="smsSendConfig.sendReservation" @change="handleUpdateChange(6)">
                <el-radio :label="0">不发送</el-radio>
                <el-radio :label="1">发送</el-radio>
              </el-radio-group>
            </div>
            <div class="comView">
              <div class="comName">优惠券发送短信:<el-tooltip class="item" effect="dark"
							content="例:尊敬的用户，您的满50减10优惠券将于 2024年9月10日 到期，请注意及时使用[XX宠物]" placement="right-start">
							<i class="el-icon-question" style="color: #F77E04;margin-left: 10px;font-size: 20px;"></i>
						</el-tooltip></div>
              <el-radio-group v-model="smsSendConfig.sendCoupon" @change="handleUpdateChange(7)">
                <el-radio :label="0">不发送</el-radio>
                <el-radio :label="1">发送</el-radio>
              </el-radio-group>
            </div>
          </div>
        </div>
        <div style="width: 50%;">
          <div class="viewTitle">短信包</div>
          <div class="pack-modul">
            <div class="main-sys" style="text-align: center;">
              <div class="text-title">买1000条</div>
              <div style="width: 100%; padding: 0px 20px; box-sizing: border-box;">
                <div class="row-cut-line" style="margin-top: 0px;"></div>
              </div>
              <div><span class="piric-sys f-f-n">50</span>元</div>
              <div><el-button type="primary" class="el-button clb-btn btn-small el-button--default"
                  style="width: 80%; margin: 15px auto;"
                  @click="dialogVisible = true"><!----><!----><span>立即购买</span></el-button></div>
            </div>
            <div class="main-sys" style="text-align: center;">
              <div class="text-title">买350条</div>
              <div style="width: 100%; padding: 0px 20px; box-sizing: border-box;">
                <div class="row-cut-line" style="margin-top: 0px;"></div>
              </div>
              <div><span class="piric-sys f-f-n">20</span>元</div>
              <div><el-button type="primary" class="el-button clb-btn btn-small el-button--default"
                  style="width: 80%; margin: 15px auto;"
                  @click="dialogVisible = true"><!----><!----><span>立即购买</span></el-button></div>
            </div>
            <div class="main-sys" style="text-align: center;">
              <div class="text-title">买100条</div>
              <div style="width: 100%; padding: 0px 20px; box-sizing: border-box;">
                <div class="row-cut-line" style="margin-top: 0px;"></div>
              </div>
              <div><span class="piric-sys f-f-n">6</span>元</div>
              <div><el-button type="primary" class="el-button clb-btn btn-small el-button--default"
                  style="width: 80%; margin: 15px auto;"
                  @click="dialogVisible = true"><!----><!----><span>立即购买</span></el-button></div>
            </div>
          </div>
        </div>
      </div>
      <div class="tableViewB" v-if="tab == 1"
        style="width: 100%;padding: 20px 30px;box-sizing: border-box;height:700px;overflow: auto;">
        <el-button class="tableBtn" type="primary" @click="handelAddClick()" size="small">新建模版</el-button>
        <el-table :data="tabList" key="1">

          <el-table-column prop="temId" label="模版ID">
          </el-table-column>
          <el-table-column prop="temName" label="模版名字">
          </el-table-column>
          <el-table-column prop="temType" label="模版类型">
            <template slot-scope="scope">
              {{ scope.row.temType == 1 ? '验证码' : scope.row.temType == 2 ? '通知' : '营销' }}
            </template>
          </el-table-column>
          <el-table-column prop="temContent" label="模版内容">
          </el-table-column>
          <el-table-column prop="temContent" label="条数">
            <template slot-scope="scope">
              {{ scope.row.temContent == null ? '--' : Math.ceil(scope.row.temContent.length / 68) }}
            </template>
          </el-table-column>
          <el-table-column prop="auditMsg" label="审核信息">
          </el-table-column>
          <el-table-column prop="auditTime" label="审核时间">
            <template slot-scope="scope">
              {{ scope.row.auditTime == null ? '--' : $public.FTime(scope.row.auditTime, 'YYYY-MM-DD HH:mm:ss') }}
            </template>
          </el-table-column>
          <el-table-column prop="auditResult" label="审核状态" width="100">
            <template slot-scope="scope">
              {{ scope.row.auditResult == 1 ? '待审核' : scope.row.auditResult == 2 ? '审核通过' : '审核失败' }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <el-button v-if="scope.row.auditResult == 3" @click="handleEditClick(scope.row)" type="text"
                size="small">编辑</el-button>
              <el-button v-if="scope.row.auditResult == 2" @click="handleSendClick(scope.row)" type="text"
                size="small">发送</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="tableViewB" v-if="tab == 2"
        style="width: 100%;padding: 20px 30px;box-sizing: border-box;height:700px;overflow: auto;">
        <div class="searchView">
          <el-input v-model="mobile" placeholder="请输入手机号" prefix-icon="iconfont el-icon-search"
            style="width: 200px;margin-right: 8px;" @keyup.enter.native="handleSearchClick"></el-input>
          <el-button type="primary" style="margin-right: 32px;" @click="handleSearchClick">查询</el-button>
          <div class="search">
            <div class="searchTitle">日期:</div>
            <el-date-picker v-model="ShortDate" type="daterange" range-separator="至" start-placeholder="开始日期"
              end-placeholder="结束日期" style="width: 260px;" @change="handleSearchClick">
            </el-date-picker>
          </div>
          <div class="searchTitle">类型:</div>
          <el-select v-model="smsStatus" placeholder="请选择" style="width: 143px;" @change="handleSearchClick">
            <el-option
              v-for="item in [{ id: 0, name: '全部' }, { id: 1, name: '成功' }, { id: 2, name: '失败' }, { id: 3, name: '待返回' }]"
              :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
          <el-button @click="handleResetClick">重置</el-button>
        </div>
        <el-table :data="ShortList" key="1">
          <el-table-column prop="msgId" label="短信记录ID">
          </el-table-column>
          <el-table-column prop="mobile" label="手机号">
          </el-table-column>
          <el-table-column prop="sendTime" label="发送时间">
            <template slot-scope="scope">
              {{ scope.row.sendTime == null ? '--' : $public.FTime(scope.row.sendTime, 'YYYY-MM-DD HH:mm:ss') }}
            </template>
          </el-table-column>
          <el-table-column prop="smsStatus" label="状态">
            <template slot-scope="scope">
              {{ scope.row.smsStatus == 1 ? '成功' : scope.row.smsStatus == 2 ? '失败' : '待返回' }}
            </template>
          </el-table-column>
          <el-table-column prop="content" label="短信内容" width="500px">
          </el-table-column>

          <el-table-column prop="chargeNum" label="计费条数">
            <template slot-scope="scope">
              {{ scope.row.chargeNum }}
            </template>
          </el-table-column>
          <el-table-column prop="originalCode" label="状态码">
          </el-table-column>
        </el-table>
        <page :pageSizes="pageSizes" @size-change="handleSizeChange" :pageIndex="page.pageIndex"
          :pageSize="page.pageSize" :total="page.total" @change="changePage"></page>
      </div>
      <div class="tableViewB" v-if="tab == 3"
        style="width: 100%;padding: 20px 30px;box-sizing: border-box;height:700px;overflow: auto;">
        <div class="searchView">
          <el-input v-model="username" placeholder="请输入用户名" prefix-icon="iconfont el-icon-search"
            style="width: 200px;margin-right: 8px;" @keyup.enter.native="handleRSearchClick"></el-input>
          <el-button type="primary" style="margin-right: 32px;" @click="handleRSearchClick">查询</el-button>
          <div class="search">
            <div class="searchTitle">日期:</div>
            <el-date-picker v-model="RechargeDate" type="daterange" range-separator="至" start-placeholder="开始日期"
              end-placeholder="结束日期" style="width: 260px;" @change="handleRSearchClick">
            </el-date-picker>
          </div>
          <el-button @click="handleResetClick1">重置</el-button>
        </div>
        <el-table :data="RechargeList" key="1">
          <el-table-column prop="rechargeId" label="变动记录ID">
          </el-table-column>
          <el-table-column prop="username" label="用户名">
          </el-table-column>
          <el-table-column prop="rechargeMethod" label="变动类型">
            <template slot-scope="scope">
              {{ scope.row.rechargeMethod == 1 ? '充值' : '扣减' }}
            </template>
          </el-table-column>
          <el-table-column prop="rechargeNum" label="变动数量">
          </el-table-column>
          <el-table-column prop="rechargeTime" label="操作时间">
            <template slot-scope="scope">
              {{ scope.row.rechargeTime == null ? '--' : $public.FTime(scope.row.rechargeTime, 'YYYY-MM-DD HH:mm:ss') }}
            </template>
          </el-table-column>
          <el-table-column prop="remark" label="备注">
          </el-table-column>
        </el-table>
        <page :pageSizes="pageSizes1" @size-change="handleSizeChange1" :pageIndex="page1.pageIndex"
          :pageSize="page1.pageSize" :total="page1.total" @change="changePage1"></page>
      </div>
    </div>
    <!-- 短信模版发送 -->
    <div v-if="dialogSend_state">
      <dialogSend @submit="submit_dialogSend" :temId="temId">
      </dialogSend>
    </div>
    <el-dialog title="客服" :visible.sync="dialogVisible" width="300px" :before-close="handleClose">
      <div style="width: 200px;height: 200px;object-fit: cover;margin: 0 auto;">
        <img src="../../../assets/img/feedback/Snipaste.png" alt="" style="width: 100%;height: 100%;">
      </div>
    </el-dialog>
  </div>
</template>

<script>
import page from "../../../components/page.vue"
import dialogSend from "../../../components/dialogSend.vue"
export default {
  components: {
    dialogSend,
    page
  },
  data() {
    return {
      tab: 0,
      show: false,
      Signature: '',//签名
      tabList: [],
      dialogVisible: false,
      smsSendConfig: {
        sendCustom: 0,
        sendRecharge: 0,
        sendCreateMember: 0,
        sendPetBirday: 0,
        sendMemberBirday: 0,
        sendCardCustem: 0,
        sendReservation: 0,
        sendCoupon: 0,
        sendRepurchase: 0
      },
      page: { //分页
        pageIndex: 1,
        pageSize: 10,
        total: 0,
      },
      page1: { //分页
        pageIndex: 1,
        pageSize: 10,
        total: 0,
      },
      pageSizes: [10, 15, 30, 60],
      pageSizes1: [10, 15, 30, 60],
      temId: '',
      dialogSend_state: false,//短信模发送弹框
      zthySignStatus: 0,
      zthySMSCount: '',
      ShortList: [],//短信记录
      ShortDate: [], //短信记录日期
      smsStatus: null,//短信记录状态
      mobile: '',//搜索短信记录手机号
      username: '',//充值记录用户名
      productType: '',
      RechargeDate: [],//充值记录日期
      RechargeList: [],//充值记录列表
    }
  },
  mounted() {
    this.GetSubAccount()
  },
  methods: {
    handleClose(done) {
      done();

    },
    handleSizeChange(val) {
      this.page.pageIndex = 1;
      this.page.pageSize = val
      this.getShortData()
    },
    handleSizeChange1(val) {
      this.page1.pageIndex = 1;
      this.page1.pageSize = val
      this.getRechargeData()
    },
    //切换分页
    changePage(num) {
      this.page.pageIndex = num
      this.getShortData();
    },
      //切换分页
      changePage1(num) {
      this.page1.pageIndex = num
      this.getRechargeData();
    },
    // 切换
    handelTabClick(type) {
      this.tab = type;
      if (this.tab == 0) {
        this.GetSubAccount()
      } else if (this.tab == 1) {
        this.GetSubAccount()
      } else if (this.tab == 2) {
        this.getShortData()
      } else if (this.tab == 3) {
        this.getRechargeData()
      }
    },
    // 新建短信模版
    handelAddClick() {
      this.$router.push({ path: 'template' })
    },
    // 编辑短信模版
    handleEditClick(row) {
      let query = {
        temId: row.temId,
        row: row
      }
      this.$router.push({ path: 'template', query })
    },
    // 短信模版发送弹框
    handleSendClick(row) {
      this.temId = row.temId
      this.dialogSend_state = true
    },
    // 短信模版发送关闭弹框
    submit_dialogSend(obj) {
      this.dialogSend_state = false
      console.log(obj);
    },
    // 修改状态
    handleUpdateChange(type) {
      let smsSendConfig = this.smsSendConfig
      if (type == 0) {  //收银短信
        smsSendConfig.sendCustom = this.smsSendConfig.sendCustom;
      } else if (type == 1) {   //充值短信
        smsSendConfig.sendRecharge = this.smsSendConfig.sendRecharge;
      } else if (type == 2) {   //商品提成
        smsSendConfig.sendCreateMember = this.smsSendConfig.sendCreateMember;
      } else if (type == 3) {  //服务提成
        smsSendConfig.sendPetBirday = this.smsSendConfig.sendPetBirday;
      } else if (type == 4) {  //小票
        smsSendConfig.sendMemberBirday = this.smsSendConfig.sendMemberBirday;
      } else if (type == 5) {  //小票
        smsSendConfig.sendCoupon = this.smsSendConfig.sendCoupon;
      } else if (type == 6) {  //小票
        smsSendConfig.sendRepurchase = this.smsSendConfig.sendRepurchase;
      }
      let params = {
        companyId: this.$store.state.userInfo.companyInfo.id,
        smsSendConfig: smsSendConfig
      };
      this.$http.post("/cms/api/ZthySms/UpdateSubAccountSendConfig", params).then(res => {
        if (res.code === 0) {
          this.show = true
        }
      })

    },
    // 短信详情
    GetSubAccount() {
      let params = {
        companyId: this.$store.state.userInfo.companyInfo.id
      }
      this.$http.get("/cms/api/ZthySms/GetSubAccount", params).then(res => {
        if (res.code === 0) {
          if (res.data) {
            this.show = true
            this.Signature = res.data.zthySign
            this.smsSendConfig = res.data.smsSendConfig
            this.zthySignStatus = res.data.zthySignStatus
            this.tabList = res.data.zthyTemplateList
            this.zthySMSCount = res.data.zthySMSCount
          }
        }
      })
    },
    // 申请开通
    handleApplyClick() {
      let params = {
        companyId: this.$store.state.userInfo.companyInfo.id
      }
      this.$http.get("/cms/api/ZthySms/CreateCompanyAccount", params).then(res => {
        if (res.code === 0) {
          this.show = true
          console.log(res);
        }
      })
    },
    // 申请签名
    changeSignature() {
      let params = {
        companyId: this.$store.state.userInfo.companyInfo.id,
        zthySMSUserName: '',
        signature: this.Signature,

      }
      this.$http.get("/cms/api/ZthySms/CreateSignature", params).then(res => {
        if (res.code === 0) {
          this.show = true
          if (res.data == true) {
            this.GetSubAccount()
          }
        }
      })
    },
    // 短信记录搜索
    handleSearchClick() {
      if (this.ShortDate == null) {
        this.ShortDate = [];
      }
      this.page.pageIndex = 1;
      this.getShortData()
    },
    //短信记录
    getShortData() {
      let params = {
        companyId: this.$store.state.userInfo.companyInfo.id,
        mobile: this.mobile,
        smsStatus: this.smsStatus,
        startTime: new Date(this.ShortDate[0]).getTime() / 1000 || '',
        endTime: new Date(this.ShortDate[0]).getTime() / 1000 || '',
        page: this.page.pageIndex,
        size: this.page.pageSize,
      }
      this.$http.post("/cms/api/ZthySms/GetSendRecordList", params).then(res => {
        if (res.code === 0) {
          this.ShortList = res.data.list
          this.page.total = res.data.count;
        }
      })
    },
    //短信记录重置
    handleResetClick() {
      this.mobile = '';
      this.ShortDate = [];
      this.smsStatus = 0;
      this.getShortData()
    },
    //充值记录
    getRechargeData() {
      let params = {
        page: this.page1.pageIndex,
        size: this.page1.pageSize,
        companyId: this.$store.state.userInfo.companyInfo.id,
        username: this.username,
        productType: this.productType,
        startTime: new Date(this.RechargeDate[0]).getTime() / 1000 || '',
        endTime: new Date(this.RechargeDate[0]).getTime() / 1000 || ''
      }
      this.$http.post("/cms/api/ZthySms/GetRechargeRecordList", params).then(res => {
        if (res.code === 0) {
          this.RechargeList = res.data.list
          this.page1.total = res.data.count;
        }
      })
    },
    //充值记录重置
    handleResetClick1() {
      this.username = '';
      this.RechargeDate = [];
      this.getRechargeData()
    },
    // 充值记录搜索
    handleRSearchClick() {
      if (this.RechargeDate == null) {
        this.RechargeDate = [];
      }
      this.page.pageIndex = 1;
      this.getRechargeData()
    },

  }
}
</script>

<style lang="scss" scoped>
.page {
  width: 100%;
  height: 100%;
  color: $fontColor;
  display: flex;
  flex-direction: column;
  background: #fff;

  // 搜索信息
  .tabView {
    height: 161px;
    border-radius: 20px;
    margin-bottom: 16px;

    .tabViewT {
      height: 80px;
      border-bottom: solid 1px #F2F2F2;
      display: flex;
      align-items: center;

      .tabItem {
        width: 116px;
        line-height: 40px;
        text-align: center;
        font-size: 16px;
        border-radius: 40px;
        margin-left: 20px;
        cursor: pointer;
        background: #FFF0E0;
      }

      .active {
        background: #F77E04;
        color: #fff;
      }
    }

    .tableViewB {
      height: 100%;
      margin-left: 20px;

      .tableBtn {
        float: right;
      }

      .searchView {
        height: 80px;
        border-radius: 20px;
        margin-bottom: 16px;
        background: #fff;
        display: flex;
        align-items: center;
        padding: 0 20px;

        .search {
          display: flex;
          align-items: center;
          margin-right: 32px;

          .searchTitle {
            font-size: 14px;
            color: $fontColor;
            margin-right: 4px;
          }
        }

        .btnView {
          flex: 1;
          text-align: right;
        }
      }

      .pack-modul {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .main-sys {
          width: 28%;
          border: 1px solid #F77E04;
          background-color: #ffffff;
          margin: 1px 12px;
          border-radius: 8px;
          font-family: PingFang;
          box-sizing: border-box;
          margin-bottom: 20px;
          min-width: 302px;

          .text-title {
            line-height: 60px;
            margin-top: 10px;
            font-size: 24px;
            font-weight: 500;
          }

          .row-cut-line {
            height: 1px;
            background-image: linear-gradient(90deg, #d9d9d9 0, #d9d9d9 50%, transparent 0);
            background-size: 14px 1px;
            background-repeat: repeat-x;
            margin-top: 20px;
          }

          .piric-sys {
            font-size: 40px;
            font-weight: 600;
          }
        }

      }
    }

    .viewTitle {
      width: 160px;
      padding-left: 9px;
      font-size: 16px;
      font-weight: bold;
      background: url(../../../assets/img/cangku/detailName.png) no-repeat;
      background-position: left top;
      background-size: 16px 16px;
      margin-bottom: 20px;
    }

    .comInput {
      margin: 10px 0;
    }

    .comView {
      margin-bottom: 20px;
    }

    .comName {
      margin-bottom: 12px;
    }

    .comImg {
      width: 40px;
      height: 40px;
      background-color: #fff;

      // border: 1px solid gray;
      img {
        width: 100%;
        height: 100%;
      }
    }

  }

  .page::-webkit-scrollbar {
    display: none;
  }
}
</style>
