<template>
  <div class="HSMask">
    <div class="HSDialog" style="min-height: 420px;min-width: 520px;padding: 10px 20px;">
      <div class="HSDialogTitleLine">
        <div class="HSDialogTitle">{{ dialogTitle }}</div>
        <img class="HSDialogCloseImg" src="../assets/img/HS/close.png" @click="close" />
      </div>
      <div class="HSDialogContent">
        <div class="HSDialogTitle">发送类型：</div>
        <el-radio-group v-model="tab">
          <el-radio :label="0">自定义手机号</el-radio>
          <el-radio :label="1">筛选会员</el-radio>
        </el-radio-group>
      </div>
      <div class="HSDialogContent" v-if="tab == 0">
        <div class="HSDialogTitle">发送账号：</div>
        <el-input v-model="mobiles" placeholder="请输入手机号" type="textarea" rows="4" style="width: 247px;"></el-input>
        <span class="HSDialogText"> <span>*</span>手机号之间用 ，隔开</span>
      </div>
      <div class="HSDialogContent" v-if="tab == 1">
        <div class="HSDialogTitle">发送会员：</div>
        <el-radio-group v-model="type">
          <el-radio :label="1">全部会员</el-radio>
          <el-radio :label="2">有余额</el-radio>
          <el-radio :label="3">充值</el-radio>
          <el-radio :label="5">一个月未消费</el-radio>
          <br>
          <el-radio :label="6">三个月未消费</el-radio>
          <el-radio :label="7">半年未消费</el-radio>
          <el-radio :label="8">一年未消费</el-radio>
        </el-radio-group>
      </div>

      <div class="HSDialogButtonLine">
        <div class="HSDialogButtonCancel" @click="close">取消</div>
        <div class="HSDialogButtonConfirm" @click="submit">确认</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    temId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      tab: 0,
      name: '',  //类型名字
      dialogTitle: '短信发送',  //弹框标题
      type: 0,
      mobiles:''
    }
  },
  mounted() {

  },
  methods: {
    // 关闭
    close() {
      this.$emit("submit", { type: "close", data: "" })
    },
    // 提交
    submit() {
     
      let params = {
        temId: this.temId,
        companyId: this.$store.state.userInfo.companyInfo.id,
        type: 0,
        mobiles: ''

      }
      if (this.tab == 0) {
        params.mobiles = this.mobiles
        params.type=0
      } else {
        params.mobiles = this.mobiles= ''
        params.type = this.type
      }
      this.$http.post("/customer/Member/SendCustomMessage", params).then(res => {
        if (res.code === 0) {
          console.log(res);
          this.$message({
							type: 'success',
							message: '成功!'
						});
            this.$emit("submit",{type:'success',data:''})
        }
      })
    },
  }
};
</script>
<style lang="scss" scoped>
.HSDialogContent {
  height: 80px;
  display: flex;
  align-items: center;

  .HSDialogText {
    font-size: 11px;
    color: red;
    text-align: center;
  }

  .HSDialogContentName {
    margin: 0 20px;
  }
}
</style>
